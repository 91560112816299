.cover {
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .title {
        color: #434343;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        padding-bottom: 20px;
    }

    .content {
        background-color: white;
        padding: 20px;
    }
}

.ant-transfer-operation {
    .ant-btn {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}