.ant-picker-header {
  align-items: baseline;
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.ant-tag {
  display: flex;
  align-items: center;
}
.hover_icon:hover {
  svg {
    color: #4096ff;
  }
}
.select_mode {
  .ant-select-selector {
    background-color: unset !important;
  }
  .ant-select-selection-overflow {
    display: none;
  }
}
.inActiveReactQuill {
  pointer-events: none;
  svg {
    fill: #ccc;
  }
}
.ant-tabs-nav-list {
  column-gap: 20px;
}
