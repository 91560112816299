body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html,
body {
  height: 100%;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-row {
  column-gap: 10px !important;
}
.active_MenuStaff {
  height: 45px;
  font-weight: 700;
}
.ant-input-disabled {
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.004);
}
.ant-select-disabled .ant-select-selector {
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.004);
}
.autoFillText input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: white;
}
.ant-btn-primary {
  background-color: #1677ff;
}
.ant-select-clear {
  margin-top: -9px !important;
}
.ant-select-selection-item-remove {
  display: flex !important;
}
.ant-picker-range-separator {
  margin-top: -6px !important;
}
