.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 15px 30px;
  &-text {
    font-size: 24px;
    font-weight: 700;
  }
}
.button-submit {
  padding: 0 40px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  &-disable {
    padding: 0 40px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #f6f6f6 !important;
    color: #a3a3a3 !important;
  }
}

// trang HairStyle
.hair-wrap {
  padding: 0 80px 30px 80px;
  box-shadow: 0px 1px 6px 0px #0000001f;
  background-color: #fff;
  margin-bottom: 30px;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 15px 0px;
  width: 60%;
  &-title {
    display: flex;
    align-items: center;
    &-text {
      font-size: 15px;
      font-weight: 600;
    }
    &-img {
      width: 16px;
      height: auto;
      margin-left: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &-btn {
    &-item {
      margin-right: 20px;
    }
  }
}
.body {
  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &-title {
    font-size: 15px;
    margin-bottom: 15px;
  }
}
// Card biến thể
.card {
  &-wrap {
    margin-right: 30px;
  }
  position: relative;
  width: 160px;
  border-radius: 12px;
  &-img {
    width: 160px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
  }
  &-button {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    width: 160px;
    padding: 10px 0;
    text-align: center;
    border: 1px solid #000;
    border-radius: 5px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      border: 1px solid #1677ff;
      color: #1677ff;
    }
  }
  &-title {
    font-size: 15px;
    width: 160px;
    text-align: center;
    margin-top: 8px;
  }
  &:hover {
    cursor: pointer;
    .card-button {
      cursor: pointer;
      border: 1px solid #1677ff;
      color: #1677ff;
    }
  }
}

// modal
.modal {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    .style-title {
      color: var(--grayscale-gray-900, #222);
      text-align: center;
      // font-family: Be Vietnam;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;
    }

    .type-title {
      color: var(--grayscale-gray-900, #222);
      // font-family: Be Vietnam;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.16px;
    }
  }

  .body {
    .item-box {
      height: 240px;
      flex-shrink: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      .item-null {
        border: 2px dashed var(--grayscale-gray-300, #d1d1d1);
        background: var(--grayscale-gray-100, #f6f6f6);
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .custom-file-input {
          display: none;
        }

        .icon {
          width: 45px;
        }

        .text {
          color: var(--grayscale-gray-500, #a3a3a3);
          text-align: center;
          // font-family: Be Vietnam;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.16px;
        }
      }

      .item-notnull {
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        border: 1px solid #a3a3a3;
        background: var(--grayscale-gray-100, #f6f6f6);
        overflow: hidden;

        .edit-button {
          cursor: pointer;
          position: absolute;
          width: 30px;
          height: 30px;
          right: 0px;
          top: 0px;
          background-color: #ffcc33;
          border-bottom-left-radius: 6px;
          justify-content: center;
          display: flex;
          align-items: center;

          .custom-file-input {
            display: none;
          }

          .edit-icon {
            width: 20px;
          }
        }

        .image {
          object-fit: contain;
        }
      }
    }
  }

  .footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .save-button {
      width: 200px;
      border-radius: 4px;
      // background: var(--primary-yellow-500, #fc3);
      // color: var(--grayscale-gray-800, #3d3d3d);
      text-align: center;
      // font-family: Be Vietnam;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .save-button2 {
      width: 200px;
      border-radius: 4px;
      background-color: #f6f6f6 !important;
      color: var(--grayscale-gray-800, #3d3d3d) !important;
      text-align: center;
      // font-family: Be Vietnam;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

// .ant-form-item {
//   width: 100% !important;
// }
