@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;400;500;600;700;800&family=Oswald:wght@400;500;600;700&display=swap');
body {
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 400;
  iframe {
    display: none !important;
  }
  .ant-layout {
    height: 100vh;
    overflow: hidden;
  }
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.f-oswald {
  font-family: 'Oswald', sans-serif;
}
.wrapper {
  background-color: #ffffff;
  padding: 16px;
}
.warning-color {
  color: #fa8c16;
}
.cancel-color {
  color: #fc625d;
}
.required-color {
  color: #e54d3e;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.ant-btn a {
  color: #ffffff;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c1c1c1;
}
.body__sidebar {
  width: 230px !important;
  max-width: unset !important;
  min-width: unset !important;
}
.ant-menu-item-selected {
  border-radius: 4px !important;
}
.ant-btn-primary {
  color: #fff !important;
  background-color: #4096ff !important;
}
.ant-layout-sider-children {
  overflow: auto;
  .ant-menu-root {
    padding-bottom: 100px;
  }
}

.ant-picker,
.ant-select-selector,
.ant-input {
  border-radius: 4px !important;
}
.ant-menu-submenu-selected {
  background: #3c7bd4;
}
.ant-input {
  height: 32px !important;
}
.ant-dropdown-placement-bottomLeft {
  z-index: 99999;
}
.ant-notification {
  z-index: 999999;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
