// .staff_list {
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn,

// }
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.ant-tag {
  display: flex;
  align-items: center;
}
.hover_icon:hover {
  svg {
    color: #4096ff;
  }
}
.select_mode {
  .ant-select-selector {
    background-color: unset !important;
  }
  .ant-select-selection-overflow {
    display: none;
  }
}
.ant-switch {
  // background-color: red !important;
}
.ant-switch:not(.ant-switch-checked) {
  background-color: #cccccc !important; // Màu nền khi switch chưa được chọn
}
.antd-tabs-header {
  .ant-tabs-nav-wrap {
    justify-content: left;
  }
}
.custom-form-details {
  // background-color: red;
  max-width: calc(100vw - 340px);
  &-form {
  }
}
.detail-block-uploadimage {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  .avatar-uploader {
    width: 20% !important;
  }
  &-text {
    width: 80% !important;
    &-label {
      width: 80px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
.ant-form-item-label {
  label {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}
.button-submit {
  margin-left: calc(25% + 10px);
}

.service-report-table {
  margin-top: 10px;
  &-header {
    display: flex;
    align-items: center;
    &-icon {
      font-size: 18px;
    }
    &-text {
      font-size: 16px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  &-body {
    margin-top: 10px;
    width: 600px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2px;
      &-title {
        font-size: 15px;
        font-weight: 400;
      }
      &-number {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}
