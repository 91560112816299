// .staff_list {
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn,

// }
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.ant-tag {
  display: flex;
  align-items: center;
}
.hover_icon:hover {
  svg {
    color: #4096ff;
  }
}
.select_mode {
  .ant-select-selector {
    background-color: unset !important;
  }
  .ant-select-selection-overflow {
    display: none;
  }
}
.ant-switch {
  // background-color: red !important;
}
.ant-switch:not(.ant-switch-checked) {
  background-color: #cccccc !important; // Màu nền khi switch chưa được chọn
}
.antd-tabs-header {
  .ant-tabs-nav-wrap {
    justify-content: left;
  }
}
.custom-form-details {
  //   background-color: red;
  max-width: calc(100vw - 300px);
  margin-left: 0;
  &-form {
    margin-left: 10%;
    margin-right: 10%;
    &-header {
      display: flex;
      align-items: center;
      &-item {
        &-once {
          margin-right: 320px;
        }
      }
    }
  }
}
.detail-block-uploadimage {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  .avatar-uploader {
    width: 20% !important;
  }
  &-text {
    width: 80% !important;
    &-label {
      width: 80px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.ant-form-item-label {
  label {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.button-submit {
  margin-left: calc(24%);
  &-table {
    margin-left: 10%;
  }
}

.ant-input {
  height: 36px;
}
:where(.css-dev-only-do-not-override-diro6f).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
  padding-top: 3px;
}

:where(.css-dev-only-do-not-override-diro6f).ant-col-16 {
  max-width: 100%;
}
