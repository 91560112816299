.page-cover {
  max-width: 500px;
  margin: 0 auto;

  .title-hair {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    font-size: 14px;

    .text {
      text-align: center;
      color: #131313;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin: 10px;

    .text {
      margin: 10px;
      text-align: center;
      color: #131313;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }

  .body {
    margin-top: 60px;
    padding: 0 16px;

    .avatar {
      margin: auto;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      border: 2px solid #d9d9d9;
    }

    .body-row {
      .box-image {
        background-color: #d9d9d9;
        display: flex;
        justify-content: center;
        max-height: 300px;
        max-width: 225px;

        .custom-file-label {
          cursor: pointer;

          .custom-file-input {
            display: none;
          }

          .button-content {
            .icon {
              color: #898989;
              font-size: 50px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .image-cover {
          overflow: hidden;
          position: relative;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 100%;
          height: 100%;

          .img {
            object-fit: contain;
          }

          .button-delete {
            z-index: 10;
            position: absolute;
            bottom: 1px;
            height: 20px;
            width: 100%;
            background: rgba(217, 217, 217, 0.8);
            align-items: center;
            justify-content: center;
            display: flex;

            .icon {
              color: #ffffff;
              margin: auto;
            }
          }
        }
      }
    }

    .button-cover {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 45px;
      flex-direction: column;

      .upload-button {
        width: 80%;
      }
    }
  }
}

.ql-editor {
  max-height: 400px;
  overflow-y: scroll;
  font-size: 16px;
}
.counter{
  margin-right: 10px;
  position: absolute;
  display: flex;
  right: 0px;
  font-size: 16px;

}